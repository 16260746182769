.exam-company-contact-text {
  margin: 0 auto;
  width: 100%;
  line-height: 1.5;
}

.mg-bt-1 {
  margin-bottom: 1rem;
}

.exam-company-contact-text {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}
