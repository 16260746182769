@media (min-width: 1030px) and (max-width: 1350px) {
  /* ------- GRIDS ------- */
  
  .grid--4-cols {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .grid--5-cols {
    grid-template-columns: repeat(3, 1fr);
  }  
}

@media (min-width: 708px) and (max-width: 1030px) {
  /* ------- GRIDS ------- */
  
  .grid--3-cols,
  .grid--4-cols,
  .grid--5-cols {
    grid-template-columns: repeat(2, 1fr);
  }
  
}

@media (max-width: 707px) and (max-width: 1030px) {
  /* ------- GRIDS ------- */
  
  .grid--2-cols
  .grid--3-cols,
  .grid--4-cols,
  .grid--5-cols {
    grid-template-columns: 1fr;
  }

  .grid{
    row-gap: 2rem
  }

  .navbar-margin{
    margin-top: 5.75rem;
  }
}


