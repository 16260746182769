.chemical-docs-intro-box-text {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 2rem;
}

.chemical-docs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  border-bottom: #104f7d 1px solid;
  padding: 1rem 0;
}

.chemical-docs-title{
  font-size: 1.5rem;
  font-weight: 600;
  margin: 4rem 0 2rem 0;
}


.chemical-docs-file-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: #104f7d;
  margin: 0;
  padding-right: 1rem;
}

/* ----- MEDIA QUERIES ------ */ 

@media (max-width: 399px) {
  .chemical-docs-wrapper {
    flex-direction: column;
    height: auto;
    align-items: stretch;
    padding: 0;
    margin-bottom: 3rem;
  }

  .chemical-docs-file-text {
    padding: 0;
    margin-bottom: 1rem;
  }

  .chemical-docs-download-button{
    margin-bottom: 1rem;
  }
}
