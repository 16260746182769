.prices-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  border-bottom: #104f7d 1px solid;
  padding: 1rem 0;
}

.prices-description-text {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 2rem;
}

.prices-file-text {
  font-size: 1.3rem;
  font-weight: 600;
  color: #104f7d;
  margin: 0;
}

/*  ------- BUTTONS ------- */
.prices-download-button {
  text-align: center;
  background-color: #3596f2;
  color: #fff;
  border: 4px solid #fff;
}

.prices-download-button:hover {
  color: #104f7d;
  background-color: #fff;
  font-weight: 600;
  border: 2px solid #104f7d;
  transition: all 0.3s;
}

/*  ------- QUERIES ------- */

@media (max-width: 460px) {
  .prices-company-contact-item-text {
    width: auto;
  }

  .prices-file-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 760px) {
  .prices-wrapper {
    gap: 1rem;
  }
}
