.chemical-about-projects-container {
  min-height: 40vh;
}

.show-project-button {
  text-align: center;
  background-color: #3596f2;
  color: #fff;
  font-weight: 600;
  border: 4px solid #fff;
  width: 10rem;
  transition: 0.5s ease-in-out all;
}

.show-project-button:hover {
  color: #104f7d;
  background-color: #fff;
  font-weight: 600;
  border: 2px solid #104f7d;
  transition: all 0.3s;
}

.chemical-about-projects-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 0;
}

.chemical-about-projects-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 3rem 0;
  width: 80%;
}

/* -------- PROJECT ITEM HEADER -------- */
.chemical-about-projects-item-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3rem;
}

.chemical-about-projects-item-header-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 2rem;
  width: 100%;
}

.chemical-about-projects-item-header-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nkfi-logo {
  display: flex;
  width: 10rem;
  margin: 0 auto;
}

.ds-logo{
  width: 20rem;
}

.chemical-about-projects-item-header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.chemical-about-projects-item-header-text {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  text-align: center;
}

.chemical-about-projects-item-header-title-text {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

/* -------- PROJECT ITEM BODY -------- */
/* ---- TABLE ---- */
.chemical-about-projects-item-body-table {
  width: 100%;
  margin: 0rem auto 3rem auto;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.1rem;
}

.chemical-about-projects-item-body-table tr {
  border: 1px solid #ddd;
}

.chemical-about-projects-item-body-table th {
  padding: 0.5rem;
  border-right: 1px solid #ddd;
}

.chemical-about-projects-item-body-table td ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
}

.chemical-about-projects-item-body-table td ul li {
  padding: 0.5rem 0;
  /* border-bottom: 1px solid #ddd; */
}

/* ---- TEXT ---- */

.chemical-about-projects-item-body-title {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
}
.chemical-about-projects-item-body-text {
  font-size: 1.1rem;
  text-align: justify;
  line-height: 1.5;
  margin: 1rem 0;
}

.chemical-about-projects-text-ul {
  padding: 0;
  list-style: none;
}

.chemical-about-projects-text-ul li {
  padding: 0.5rem 0;
}

.chemical-projects-danube-list-ul{
  padding-left: 1rem;
}
.chemical-projects-danube-list-item{
  margin: 0;
  list-style-type: disc;
 
}

/* ----- MEDIA QUERIES  ---- */
@media screen and (min-width: 699px) {
  .chemical-about-projects-item-header-grid {
    grid-template-columns: 1fr 2fr;
  }
}

@media screen and (max-width: 599px) {
  .show-project-button {
    width: 9rem;
  }
  .chemical-about-projects-item-container {
    padding: 0;
    width: 100%;
  }
  .chemical-about-projects-item-header-grid {
    grid-template-columns: 1fr;
    row-gap: 2rem;
    width: 100%;
  }

  .chemical-about-projects-item-header-title-container {
    width: 100%;
  }

  .chemical-about-projects-item-header-title-text {
    font-size: 1.2rem;
  }

  .chemical-about-projects-item-header-text-container {
    width: 100%;
  }

  .chemical-about-projects-item-wrapper {
    width: 100%;
    gap: 2rem;
  }
}

@media screen and (max-width: 499px) {
  .chemical-about-projects-item-body-table tbody {
    display: block;
  }
  .chemical-about-projects-item-body-table tbody tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    border: none;
  }
  .chemical-about-projects-item-body-table tbody tr th,
  .chemical-about-projects-item-body-table tbody tr td {
    display: block;
    width: 100%;
    border-right: none;
    padding-left: 0;
  }

  .chemical-about-projects-item-body-table tbody tr td {
    border-bottom: none;
  }
}
