.exam-package-szures-box-text {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.exam-package-szures-box-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0;
  margin-bottom: 2rem;
  padding: 0;
}

.exam-package-szures-box-list {
  list-style: none;
  padding: 0;
}

.exam-package-szures-box-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 3rem; */
  /* width: 15rem; */
  font-size: 1.2rem;
  font-weight: 400;
  color: #104d7f;
  line-height: 1.5;
  padding: 0.1rem 1rem 0.1rem 0.5rem;
  margin-bottom: 1rem;
  border-left: 5px solid #104d7f;
  transition: all 0.3s ease-in-out;
}

.exam-package-szures-box-list-item:hover {
  border-left: 5px solid #3596f2;
  color: #3596f2;
}

/* ---------- QUERIES ---------- */

@media (max-width: 499px) {
  .exam-package-szures-box-list-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 500px) and (max-width: 789px) {
  .exam-package-szures-box-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 790px) and (max-width: 996px) {
  .exam-package-szures-box-list-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
