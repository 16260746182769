.chemical-exam-cta-container {
  margin: 5rem 0;
}

.chemical-exam-cta-text {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8;
  text-align: left;
  margin: 2rem 0;
}

@media (max-width: 499px) {
  .chemical-exam-cta-button {
    background-color: #3596f2 !important;
    color: white !important;
  }
}
