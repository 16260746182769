.heading-primary{
  padding: auto 2rem;
}

.services-container{
  margin: 0 0 5rem 0;
}

.chemical-services-grid{
  column-gap: 4rem;
  row-gap: 2rem;
}

.col-sm-3{
  width: 100%;
}

.chemical-services-flex-box{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* .services-flex-box-reverse{
  display: flex;
  flex-direction: row-reverse;
} */

.services-grid-box-icon{
  margin: auto 0;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
}

.services-grid-box-icon-img{
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  display: block;
}

.services-grid-box-text-box{
  align-self: center;
}

.services-grid-box-title{
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin: 1rem 0;
  color: #3596F2;
}

.chemical-services-grid-box-text{
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 1rem 0;
}

.services-grid-box-title.right-aligned{
  text-align: right;
}

.services-grid-box-title.left-aligned{
  text-align: left;
}

.services-grid-box-text.right-aligned{
  text-align: right;
}

.services-grid-box-text.left-aligned{
  text-align: left;
}


/* ------------------ Media Queries ------------------ */


@media (min-width: 714) and (max-width: 1030px) {
  .services-grid{
    column-gap: 2rem;
    row-gap: 2rem;
  }
}

@media (max-width: 714px) {
  .services-grid{
    grid-template-columns: 1fr;
    column-gap: 2rem;
  }
}

@media (max-width: 499px) {
  .heading-primary{
    font-size: 2.4rem;
  }

  .services-grid-box-title{
    font-size: 1.3rem;
  }
}