.appointment-container {
  padding: 0 3.2rem;
}

.appointment-info-text{
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  width: 80%;
  margin-bottom: 2rem;
}

.appointment-wrapper {
  width: 80%;
}

.appointment-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;

}

.appointment-list-item {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: #104f7d 1px solid;
}

.appointment-text {
  font-size: 1rem;
  font-weight: 500;
}

.appointment-btn {
  display: flex !important;
  justify-content: left;
  align-items: center;
  width: 13rem;
  height: 3.5rem;
  padding: auto 1rem !important;
  text-align: center;
  background-color: #3596f2;
  color: #fff;
  text-wrap: nowrap;
  margin-bottom: 1rem;
}

.appointment-btn-icon {
  margin-right: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  transition: opacity 0.3s;
}

.appointment-btn:hover .appointment-btn-icon {
  display: none; /* Hide the image on hover */
}

.appointment-btn:hover{
  justify-content: center;
}

.appointment-btn:hover .appointment-text {
  position: absolute;
  top: 50%;
  left: 50%; /* Position it at 50% from the left */
  transform: translate(-50%, -50%); /* Center the text both horizontally and vertically */
}

.appointment-btn:hover {
  color: #104f7d;
  background-color: #fff;
  border: 2px solid #104f7d;
  transition: all 1s;
}

/* -------------------- QUERIES ---------------------- */
@media (min-width: 720px) and (max-width: 833px) {
  .appointment-list {
    width: 100%;
  }

  .appointment-list-item {
    gap: 0;
  }
  .appointment-text {
    width: 60%;
    line-height: 1.5rem;
  }
}

@media (max-width: 580px) {
  .appointment-wrapper {
    width: 100%;
  }
  .appointment-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .appointment-list-item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  .appointment-text {
    text-align: center;
    width: 100%;
    font-weight: 500;
  }

  .appointment-btn {
    width: 12rem;
    justify-content: space-around;
  }
}

@media (min-width: 460px) and (max-width: 580px) {
  .appointment-btn {
    width: 12rem;
    justify-content: space-around;
  }
}
