.navbar-margin {
  margin-top: 10rem;
}

.examination-company-writeus-text{
  font-size: 1.2rem;
  line-height: 1.5;
}

.text-box {
  margin-bottom: 3rem;
}

.text-box p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.heading-tertiary {
  margin: 3.2rem 0;
}

.accordion-button{
  font-size: 1.2rem;
  font-weight: 600;
  color: #333 !important;
}

.examination-company-accordion-suggested-text{
  font-size: 1.2rem;
  line-height: 1.5;
}