.chemical-examination-list{
    width: 100%;
    margin-top: 20px;
    list-style: none;
    padding-left: 0;
}

.chemical-examination-list-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    text-decoration: none;
    padding-left: 0;
}