.impressum-text-header{
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.impressum-text-title{
  font-size: 1.2rem;
  font-weight: 600;
  margin: 2rem 0;
}

.impressum-text{
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.impressum-text a{
  text-decoration: none;
  color: #3596f2;
  font-weight: 600;
}

.impressum-text a:hover{
  color: #104f7d;
}

.impressum-text a:-webkit-any-link {
  cursor: pointer;
}

.impressum-text-extra{
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin: 2rem 0;
}