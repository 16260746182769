.chemical-priceoffer-contact-box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
  margin-bottom: 3rem;
}

.chemical-priceoffer-contact-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
}

.chemical-priceoffer-contact-text-field{
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}
.chemical-priceoffer-contact-text-title {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5;
}


.chemical-priceoffer-contact-text-body {
  font-size: 1.1rem;
  font-weight: 400;
}

.chemical-priceoffer-contact-text-field:first-of-type{
  margin-bottom: 0.5rem;
}

.chemical-priceoffer-contact-text-body-extra-content{
  margin-top:0;
}

.chemical-priceoffer-contact-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.8rem;
}

.chemical-priceoffer-contact-btn {
  background-color: #3596f2;
  color: white;
  font-size: 0.8rem;
  width: 12rem;
}

.chemical-priceoffer-contact-btn:hover {
  background-color: #104d7f;
  color: white;
}

/* ---------------- QUERIES ---------------- */

@media (max-width: 620px) {
  .consultation-container {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 499px) {
  .chemical-priceoffer-contact-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    position: relative;
    margin: 2rem auto;
  }

  .chemical-priceoffer-contact-box-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 1rem auto;
  }

  .chemical-priceoffer-contact-text-body {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8;
  }

  /* .chemical-priceoffer-contact-box-body::before,
  .chemical-priceoffer-contact-box-body::after {
    content: "";
    display: block;

    position: absolute;
    top: 15%;
    left: 0;
    width: 1px;
    height: 70%;
    z-index: -1;
    border-radius: 4.5px;
  }

  .chemical-priceoffer-contact-box-body::before {
    width: 100%;
    padding-bottom: 80%;
  }

  .chemical-priceoffer-contact-box-body::after {
    width: 100%;
    padding-bottom: 80%;
  } */

  .chemical-priceoffer-contact-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
  }

  .chemical-priceoffer-contact-btn {
    width: 100%;
    background-color: #3596f2;
    color: white;
  }

  .chemical-priceoffer-contact-btn:hover {
    width: 80%;
    background-color: #104d7f;
    color: white;
  }
}
