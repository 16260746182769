.hero-container {
  margin-top: 6.2rem;
}

.carousel-control-prev {
  position: absolute;
  top: 0;
  left: -6%;
}

.carousel-control-next {
  position: absolute;
  top: 0;
  right: -6%;
}

@media (max-width: 991px) {
  .hero-container {
    margin-top: 5.75rem;
  }
}

@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}


@media (max-width: 600px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 1rem;
    height: 1rem;
  }
}
