.chemical-sample-rec-intro p {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
}

.chemical-sample-rec-docs-wrapper {
  gap: 3rem;
}

.chemical-sample-rec-subheading{
  font-size: 1.2rem;
  font-weight: 600;
}

.chemical-sample-rec-docs-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.chemical-sample-rec-docs-button{
  width: 6rem;
}

.chemical-sample-rec-intro p {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
}

.chemical-sample-rec-docs-wrapper {
  gap: 3rem;
}

.chemical-sample-rec-subheading{
  font-size: 1.2rem;
  font-weight: 600;
}

.chemical-sample-rec-docs-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.chemical-sample-rec-docs-icon {
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  /* margin-left: 0.5rem; */
  font-style: normal;
}

.chemical-sample-rec-docs-text {
  font-size: 1.1rem;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
}

.chemical-sample-rec-docs-text a {
  color: #3596f2;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.chemical-sample-rec-info p {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
}

.chemical-sample-rec-info button {
  background-color: #3596f2;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
} 

.chemical-sample-rec-info button:hover {
  background-color: #104d7f;
  color: white;
}



.modal-show {
  display: block;
} 

.modal-content-text{
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
}

.modal-btn {
  background-color: #8888;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.modal-btn:hover {
  background-color: #666;
}

/* ---------- MEDIA QUERIES ---------- */
@media (max-width: 930px) {
  .chemical-sample-rec-docs-title-wrapper {
    width: 75%;
  }
}

@media (max-width: 560px) {
  .chemical-sample-rec-docs-wrapper {
    flex-direction: column;
    height: auto;
    align-items: stretch;
    padding-bottom: 0;
    margin-bottom: 2rem;
    gap: 0.5rem;
  }
  .chemical-sample-rec-docs-title-wrapper {
    width: 100%;
  }
  .chemical-sample-rec-docs-button-wrapper {
    width: 100%;
    flex-direction: column;
  }
  .chemical-sample-rec-docs-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .chemical-sample-rec-info{
    margin-top: 3rem;
  }
}

@media (min-width: 400px) and (max-width: 560px) {
  .chemical-sample-rec-docs-wrapper{
    gap: 2rem;
  }
}