.chemical-qc-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem 0rem;
    width: 100%;
}

.chemical-qc-box-title{
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin: auto 0;
}

.chemical-qc-contact-box-text,
.chemical-qc-box-text{
    width: 75%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8;
    text-align: left;
    margin: 1rem 0;
}

.chemical-qc-box-text{
    text-align: justify;
}

.chemical-qc-box-text-list-item:first-of-type{
    margin-top: 0;
}

@media (max-width: 715px) {
    .chemical-qc-box-title{
        font-size: 1.5rem;
    }
    .chemical-qc-box-text{
        width: 100%;
    }
}