.sitemap-list {
  list-style-type: none;
  padding: 0;
}

.sitemap-list h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 3rem 0 1rem 0;
}

.sitemap-list h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 1rem 0;
}

.sitemap-list li {
  margin: 0.5rem 0;
  list-style-type: none;
}

.sitemap-list a {
  text-decoration: none;
  color: #007bff;
}

.sitemap-list a:hover {
  text-decoration: underline;
}

.sitemap-list .no-url span {
  color: #333;
  font-weight: 600;
}