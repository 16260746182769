.chemical-exam-package-szures-box-list-grid-1-col{
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 0;
}

.chemical-exam-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 3rem; */
  /* width: 15rem; */
  font-size: 1.2rem;
  font-weight: 400;
  color: #104d7f;
  line-height: 1.5;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.chemical-exam-list-item:hover {
  color: #3596f2;
}

.chemical-exam-sampling-inside-container{
  border-top: 0.2px solid transparent;
  margin-bottom: 0;
  padding-bottom: 3rem;
}

.chemical-exam-sampling-inside-even{
  background-color: #104f7d;
}

.chemical-exam-sampling-inside-even-white-text{
  color: white !important;
}

.chemical-exam-sampling-inside-even-white-text:hover{
  color: #3596F2 !important;
}

.chemical-exam-sampling-list-item-even{
  border-left: 5px solid white !important;
}

.chemical-exam-sampling-list-item-even:hover{
  border-left: 5px solid #3596F2 !important;
}



