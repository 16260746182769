.hero-container {
  margin-top: 6.2rem
}

@media (max-width: 991px) {
  .hero-container {
    margin-top: 5.75rem
  }
}

