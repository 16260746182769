.news-row {
  position: relative;
  padding-left: 3rem;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-x: scroll;
  overflow-y: hidden;
}

.news-card {
  width: 100%;
  margin: 0 2rem 3rem 1rem;
  box-sizing: border-box;
}
/* .news-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
} */

.card-with-shadow {
  width: 20rem;
  height: 100%;
}

.border-usual {
  border-radius: 9px;
}

.card-img-top {
  border-radius: 4.5px 4.5px 0 0 !important;
}

.news-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.news-card-text-col {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 7rem; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; /* Add this property */
  line-height: normal; /* Adjust line-height as needed */
  max-height: 7em; /* You may need to adjust this based on your font size */
}

.news-card-title {
  display: -webkit-box; /* Enables -webkit-line-clamp and -webkit-box-orient */
  -webkit-line-clamp: 2; /* Number of lines you want to display */
  -webkit-box-orient: vertical; /* Makes the text vertical */
  overflow: hidden; /* Hides the text that overflows the content box */
  text-overflow: ellipsis; /* Displays an ellipsis when the text overflows */
}

.news-card-footer {
  margin-top: auto;
}

.news-card-read-button {
  margin: 1rem 0;
}

.news-card-read-button:link,
.news-card-read-button:visited {
  display: block;
  margin: 1rem 0 1 0rem;
  width: 10rem;
  height: 3rem;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.news-card-read-button:hover {
  background-color: #104d7f;
  border-color: #104d7f;
  color: #fff;
}
/* Hide scrollbar for webkit-based browsers */
.news-row::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

/* Track */
.news-row::-webkit-scrollbar-track {
  background: transparent; /* Set the background color to match your container */
}

/* Handle */
.news-row::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the scrollbar thumb */
  border-radius: 4px; /* Add a border-radius for aesthetics if needed */
}