.text-color-white {
  color: white !important;
}

.text-color-blue {
  color: #104d7f !important;
}

.text-color-white:hover,
.text-color-blue:hover {
  color: #3596f2 !important;
}

.chemical-exam-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 3rem; */
  /* width: 15rem; */
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}
