.popupBackdrop {
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.popup-image {
  width: 100%;
  height: auto;
  border-radius: 9px;
  cursor: pointer;
}

.popup-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  width: 32px;
  height: 32px;
  border: none;
  background: #104d7f;
  padding: 0;
  color: white;
  outline: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-close:hover {
  background: rgba(0, 0, 0, 0.7); /* Darken background on hover */
}

.popup-close:before,
.popup-close:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  background-color: white;
}


.popup-close:before {
  transform: rotate(45deg);
}

.popup-close:after {
  transform: rotate(-45deg);
}

@keyframes slideUp {
  from {
    transform: translateY(100%); /* Start from below the screen */
    opacity: 0; /* Start from fully transparent */
  }
  to {
    transform: translateY(0); /* End at its natural position */
    opacity: 1; /* Fully visible */
  }
}

.popup {
  animation: slideUp 0.5s ease-out forwards; /* Apply the animation */
}
