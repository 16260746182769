.news-buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 96.4%;
  top: 105px;
}

.scroll-button {
  width: 3.5rem;
  height: 3.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.2;
  border: 0px solid;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2rem;
  cursor: pointer;
  z-index: 1;
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0.8;
}

.scroll-left {
  position: absolute;
  top: 280%;
}

.scroll-right {
  position: absolute;
  top: 280%;
  right: -82px;
  margin-right: 2rem;
}

.news-row {
  position: relative;
  padding-left: 3rem;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.news-card {
  width: auto;
  margin: 0 2rem 3rem 1rem;
  box-sizing: border-box;
}

.card-with-shadow {
  width: 20rem;
  height: 100%;
}

.border-usual {
  border-radius: 9px;
}

.card-img-top {
  border-radius: 9px 9px 0 0;
}

.news-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.news-card-text-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 7rem;
}

.news-card-title {
  text-align: start;
}

.news-card-footer {
  margin-top: auto;
}

.news-card-read-button {
  margin: 1rem 0;
}

.news-card-read-button:link,
.news-card-read-button:visited {
  display: block;
  margin: 1rem 0 1 0rem;
  width: 10rem;
  height: 3rem;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.news-card-read-button:hover {
  background-color: #104d7f;
  border-color: #104d7f;
  color: #fff;
}
/* Hide scrollbar for webkit-based browsers */
.news-row::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

/* Track */
.news-row::-webkit-scrollbar-track {
  background: transparent; /* Set the background color to match your container */
}

/* Handle */
.news-row::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the scrollbar thumb */
  border-radius: 4px; /* Add a border-radius for aesthetics if needed */
}

@media (max-width: 768px) {
  .scroll-right {
    right: -60px;
  }
}
