/* ------ PARTNERS GRID */
.about-partners-header{
  margin: 2rem 0 1rem 0;
}

.about-partners-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.about-partners-box {
  text-decoration: none;
  padding: 1rem;
}

.about-partner-card-grid {
  display: grid;
  row-gap: 0.6rem;
}

.about-partner-name-grid {
  display: grid;
  position: relative;
}

.about-partner-name {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2rem;
  font-weight: 600;
  color: #3596f2;
  /* border-left: 2px solid #3596f2; */
}

.about-partner-name:hover {
  color: #333;
}

.about-partner-description-text {
  font-size: 1rem;
  color: #333;
}

a:-webkit-any-link {
  cursor: pointer;
}

/* ----------- QUERIES ----------- */

@media (max-width: 1024px) {
  .about-partners-container {
    padding: 0 3.2rem;
  }
}

@media (max-width: 900px) {
  .about-partners-grid-container {
    padding: 0 2rem;
  }
  .about-partners-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 499px) {
  .about-partners-grid-container {
    padding: 1rem;
  }

  .about-partners-grid {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    justify-content: center;
  }

  .about-partners-box {
    border-bottom: 1px solid #cdcdcd;
  }

  .about-partners-box:last-of-type {
    border-bottom: none;
  }
}
