.landing-page-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh;
  overflow: hidden;
  background-color: #3596f2;
}

.landing-page-logo-wrapper {
  position: relative;
  max-width: 30rem; /* Ensure the logo doesn't overflow */
  z-index: 999;
  bottom: 10%;
}

.landing-page-title {
  color: #104f7d;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  z-index: 999;
}

.landing-page-logo {
  display: block;
  margin: 0 auto; /* Center horizontally */
  max-width: 50%; /* Ensure the logo doesn't overflow */
  height: auto; /* Maintain aspect ratio */
  position: relative; /* Adjust the position */
  z-index: 999;
}

.chemical-page-container {
  position: absolute;
  width: 50%;
  top: 0;
  height: 100vh;
  overflow: hidden;
  transition: all 0.3s;
  background-size: cover;
  text-align: center;
}
.medical-page-container {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 0;
  height: 100vh;
  overflow: hidden;
  transition: all 1s;
  background-size: cover;
  text-align: center;
}

/* ----------- BUTTONS ----------- */

.page-selector-button:link,
.page-selector-button:visited {
  position: absolute;
  bottom: 15%;
  left: 28%;
  width: 50%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.5rem;
  color: #fff;
  background-color: #3596f2;
  font-weight: 600;
  border: 4px solid #fff;
  border-radius: 9px;
  transition: all 0.3s;
}

.page-selector-button:hover,
.page-selector-button:active,
.page-selector-button:focus {
  color: #fff;
  background-color: #3596f2;
  font-weight: 600;
  padding: 1.6rem;
  border: 4px solid #fff;
  transition: all 0.3s;
}

.chemical-page-container:hover {
  background-image: url("../../assets/landing/landing_chemical_1200x1400_2.png");
}

.medical-page-container:hover {
  background-image: url("../../assets/landing/landing_medical_2_1200x1400.png");
}


/* ----------- QUERIES ----------- */

@media (max-width: 1000px) {
  .page-selector-button:link,
  .page-selector-button:visited {
    left: 20%;
    width: 60%;
    height: 10%;
    font-size: 1.3rem;
  }

  .chemical-page-container:hover {
    background-image: url("../../assets/landing/landing_chemical_small_300x677_2.png");
  }
  
  .medical-page-container:hover {
    background-image: url("../../assets/landing/landing_medical_small_300x677.png");
  }
}

@media (max-width: 736px) {
    .landing-page-logo-wrapper {
      bottom: 10%;
    }

  .page-selector-button:link,
  .page-selector-button:visited {
    left: 15%;
    width: 70%;
    height: 10%;
    font-size: 1.3rem;
  }
}

@media (max-width: 650px) {
  .landing-page-logo-wrapper {
    bottom: 10%;
  }


  .page-selector-button:link,
  .page-selector-button:visited {
    left: 15%;
    width: 70%;
    height: 10%;
    font-size: 1.3rem;
    text-wrap: wrap;
  }
}

@media (max-width: 499px) {
  .landing-page-logo-wrapper {
    bottom: 20%;
  }

  .page-selector-button:link,
  .page-selector-button:visited {
    bottom: 30%;
    left: 15%;
    width: 70%;
    height: 10%;
    font-size: 1rem;
    text-wrap: wrap;
  }

  .chemical-page-container:hover {
    background-image: none;
    transition: none;
  }
  
  .medical-page-container:hover {
    background-image: none;
    transition: none;
  }
}
