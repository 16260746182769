.contact-us-container {
  width: 100%; /* Change the width to 100% to make it responsive */
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.contact-us-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0;
  row-gap: 1rem;
}

.contact-box {
  position: relative;
  top: -40px;
  display: flex;
  flex-direction: column;
  width: 90%; /* Set a fixed width for each contact box */
  text-align: center;
  align-items: center;
  padding: 3rem 4rem;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #eaf0f6;
  border-radius: 4.5px;
}

.contact-box-icon,
.contact-box-title,
.contact-box-text,
.contact-box-link {
  margin-bottom: 1rem;
}

.contact-box-title {
  height: 3rem;
}

.contact-box-icon {
  width: 4rem;
  height: auto;
  color: #3596f2;
}

.contact-box-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.contact-box-link {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}

.contact-box-text {
  font-size: 1rem;
  line-height: 1.5;
}

/* ------------- SECTION GMAPS ------------- */

.contact-us-gmap-section {
  margin: 3rem 0;
}

.contact-us-gmap-section-title {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin: 3rem 0;
}

.contact-us-gmap-container {
  padding: 0 3.2rem;
}

.contact-us-gmap-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eaf0f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-us-gmap-iframe {
  width: 100%;
}

.contact-us-gmap-content {
  padding: 3rem;
}

.contact-us-gmap-content-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  color: #333;
  margin-bottom: 1rem;
}

.contact-us-gmap-content-address,
.contact-us-gmap-open-text,
.contact-us-gmap-content-contact-phone,
.contact-us-gmap-content-contact-email {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333;
  margin-bottom: 1rem;
}

.contact-us-gmap-content-open-hours,
.contact-us-gmap-content-contact {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.5;
  color: #333;
  margin-bottom: 1rem;
}

/* ------------- SECTION GET THERE ------------- */

.contact-us-get-there-section {
  margin: 3rem 0;
  padding: 0 3.2rem;
}

.contact-us-get-there-section-title {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin: 3rem 0;
}

.contact-us-get-there-text {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #333;
  margin-bottom: 1rem;
}

/* ------------- QUERIES ------------- */

@media screen and (max-width: 412px) {
  .contact-us-container {
    padding: 0 1rem;
  }

  /* ------------- SECTION GMAPS ------------- */
  .contact-us-gmap-container {
    padding: 0 1rem;
  }

  /* ------------- GET THERE GMAPS ------------- */
  .contact-us-get-there-section {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 804px) {
  .contact-us-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
  }

  .contact-box {
    width: 100%;
    padding: 3rem 1rem;
    top: -30px;
  }

  /* ------------- SECTION GMAPS ------------- */

  .contact-us-gmap-wrapper {
    flex-direction: column;
  }
}

@media (min-width: 804px) and (max-width: 906px) {
  .contact-us-container {
    padding: 0;
  }

  .contact-box {
    width: 80%;
    top: -30px;
  }
}

@media (min-width: 808px) and (max-width: 1325px) {
  .contact-us-gmap-wrapper {
    padding-left: 2rem;
  }
}
