.news-page-container{
    width: 90%;
    padding: 0 5rem;
}

.news-page-title{
  margin-bottom: 2rem;
}

.exam-category-page-date{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 3rem;
}

.exam-category-page-content{
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.1px;
    margin-bottom: 3rem;
}

.exam-category-page-content img{ 
    width: 100%;
    height: auto;
    margin: 2rem 0;
}

@media screen and (max-width: 768px) {
    .news-page-container{
        padding: 0 2rem;
    }

    .news-page-title{
        margin-top: 3rem;
    }
}

@media screen and (max-width: 576px){
    .news-page-container{
        padding: 0;
    }

    .news-page-title{
        margin-top: 2rem;
    }
}

