.chemical-exam-contact-box{
  margin: 3rem 0 5rem 0;
}

.chemical-exam-contact-box-intro-text{
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8;
}

.chemical-exam-contact-box-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 2rem 0;
}

.chemical-exam-contact-text-wrapper{
  margin: 2rem 0;
}

.chemical-priceoffer-contact-text-body-phone-wrapper{
  display: grid;
  grid-template-columns: 5rem 1fr;
}

.chemical-priceoffer-contact-text-body-phone{
  display: grid;
}

.chemical-priceoffer-contact-text-body-phone-text{
  font-size: 1.1rem;
}

/* ----------- MEDIA QEURIES -----------  */
@media only screen and (max-width: 499px){
  .chemical-priceoffer-contact-text-body-phone-wrapper{
    grid-template-columns: 1fr;
  }

  .chemical-priceoffer-contact-text-body{
    margin-bottom: 0.5rem;
  }
}