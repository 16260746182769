.about-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 3rem 0;
  padding: 0;
  gap: 0;
  box-sizing: border-box;
  /* background-color: #3596F2; */
}

.about-heading {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  margin: 5rem 0;
}

.about-heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.05;
  margin: 2rem 0;
}

.about-story-item-box {
  padding: 0 2rem;
  width: auto;
  color: #333;
  font-weight: 500;
}

.about-story-item-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.about-ending-text {
  margin: 5rem auto 2rem auto;
  padding: 0;
  text-align: center;
  font-size: 1.4rem;
  color: #333;
}

.about-ending-text-last {
  margin: 2rem;
}

.about-story-item-pic-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.about-story-item-pic {
  width: auto;
  height: 20rem;
  border-radius: 0.5rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  gap: 2rem;
}

.about-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 3.5rem;
  padding: auto 1rem !important;
  text-align: center;
  background-color: #3596f2;
  color: #fff;
  border: 2px solid #fff;
}

.about-button:hover {
  color: #104f7d;
  background-color: #fff;
  border: 2px solid #104f7d;
  transition: all 0.3s;
}

.hidden {
  opacity: 0;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); Add a subtle shadow */
  background-color: #ffffff; /* Set a background color */
  transition: all 1s;
}

.shown {
  opacity: 1;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); Add a subtle shadow */
  background-color: #ffffff; /* Set a background color */
  transition: all 1s;
}

/*  ------------------- Media queries -------------------*/

@media (min-width: 845px) and (max-width: 1449px) {
  .about-story-item-pic {
    width: auto;
    height: 15rem;
    /* border-radius: 0.5rem; */
  }
}

@media (min-width: 611px) and (max-width: 844px) {
  .about-grid {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    padding: 0 2rem;
    row-gap: 1rem;
  }

  .container {
    padding: 0 2rem;
    text-align: left;
  }

  .about-story-item-pic-box {
    padding: 0 2rem;
  }

  .about-ending-text {
    margin: 0 auto 2rem auto;
    font-size: 1rem;
    font-weight: 400;
    text-align: start;
  }

  .about-ending-text-last {
    font-size: 1rem;
    font-weight: 400;
  }

  .button-container {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto 5rem auto;
  }
}

@media (max-width: 610px) {
  .about-grid {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    padding: 0;
    row-gap: 1rem;
  }

  .about-story-item-pic {
    width: auto;
    height: 10rem;
    /* border-radius: 0.5rem; */
  }

  .button-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
