html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  /* font-size: 62.5%; */
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  line-height: 1;
  font-weight: 400;
  color: #333;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
