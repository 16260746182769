.outer-div {
  width: 100%;
  background-color: #3596f2;
}

.footer-container {
  background-color: #3596f2;
  color: white;
  max-width: 120rem;
  padding: 2rem 3.2rem;
  margin: 0 auto;
}

.footer-container .copyright-row {
  width: 100%; /* Takes full width */
  text-align: left; /* Center the content horizontally if desired */
  padding: 20px 0; /* Adjust padding as needed */
  background-color: #3596f2; /* Match the footer background */
  color: white;
  /* Any other styles you may want to apply */
}

.copyright-row .copyright {
  margin-bottom: 0;
}

.logo-col {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: block;
  margin-bottom: 1rem;
}

.footer_logo_img {
  width: 15rem;
  /* margin-bottom: 1.6rem; */
}

.social-links {
  list-style: none;
  display: flex;
  padding: 0;
  gap: 2.4rem;
  color: white;
  /* justify-content: center; */
}

.facebook-logo {
  width: 2rem;
  height: auto;
}

.social-icon {
  width: 8rem;
  color: white;
}

.copyright {
  font-size: 0.8rem;
  color: white;
  /* text-align: center; */
}

.copyright a {
  color: white;
  text-decoration: none;
  font-style: italic;
}

.sitemap {
  text-decoration: none;
}

.footer-heading {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: white;
}

.contacts {
  font-style: normal;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  color: white;
  margin-bottom: 0;
}

address .contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.address {
  margin-bottom: 1.2rem;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  list-style: none;
  padding: 0;
  /* text-decoration: none;
  font-size: 1.6rem; */
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #104d7f;
}

.footer-perm-number {
  cursor: default;
}

/* ----------- QUERIES ----------- */

@media (min-width: 1173px) {
  .footer-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1018px) and (max-width: 1172px) {
  .footer-grid {
    grid-template-columns: repeat(4, 1fr);
    column-width: 5rem;
    column-gap: 2rem;
  }
}

@media (min-width: 708px) and (max-width: 1017px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }
  .logo-col,
  .docs-col {
    grid-row: 2;
  }
}

@media (min-width: 620px) and (max-width: 707px) {
  .footer-grid {
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }
  .logo-col,
  .docs-col {
    grid-row: 2;
  }
}

@media (max-width: 619px) {
  .footer-container {
    padding: 2rem;
  }

  .footer-grid {
    grid-template-columns: 100%;
    gap: 1rem;
    padding: 0;
    word-break: break-word;
  }

  .contact-div{
    padding: 0;
  }

  .footer_logo_img {
    width: 10rem;
  }

  .address-col {
    grid-row: 2;
  }

  .contact-div {
    gap: 0;
  }

  .customer-service {
    margin-bottom: 1.2rem;
  }

  .logo-col {
    grid-row: 4;
  }
}
