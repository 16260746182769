.news-page-container {
  width: 90%;
  padding: 0 5rem;
}

.news-page-title {
  margin-bottom: 2rem;
}

.exam-category-page-date {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.exam-category-page-content {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  margin-bottom: 3rem;
}

.chemical-newspage-content {
  font-size: 1.1rem;
}

.exam-category-page-content img {
  width: 100%;
  height: auto;
  margin: 2rem 0;
}

.news-image-single-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.news-image {
  width: 100%;
  max-width: 800px;
  margin: auto;
  height: auto;
}

.news-image-single{
  max-width: 500px;
  margin: 0 auto;
}

.image-caption {
  text-align: center;
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
}
