.hero-container {
  margin-top: 6.1rem
}

.banner-hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 991px) {
  .hero-container {
    margin-top: 5.75rem
  }
}

@media (max-width: 844px) {
  .banner-hero-img{
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

