.about-partner-container {
  /* 1140px */
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto 3.2rem auto;
}

.about-heading {
  margin-bottom: 3.2rem;
}

.about-partners-grid-container {
  padding: 0 8rem;
}

.about-partners-header {
  font-weight: 600;
  margin-bottom: 2rem;
}

.about-partners-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 1rem;
}

.about-partners-text-last {
  margin-bottom: 3.2rem;
}

.about-partners-join-button:link,
.about-partners-join-button:visited {
  display: block;
  margin: 2rem auto 0 0;
  width: 10rem;
  padding: 0.8rem;
  height: 3rem;
  align-items: center;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

/* ----------- QUERIES ----------- */

@media (max-width: 714px) {
  .about-heading {
    margin-bottom: 3.2rem;
  }

  .about-partners-join-box {
    margin-bottom: 3.2rem;
    line-height: 1.5;
  }
}

@media (max-width: 499px) {
  .about-heading {
    margin-bottom: 3.2rem;
  }
}
