.not-implemented-page-button {
  display: block;
  margin: 4rem auto;
  color: white;
  background-color: #3596F2;
  font-weight: 600;
}

.not-implemented-page-button:hover {
  background-color: white;
  border-color: #104d7f;
  color: #3596F2;
}

/* ------------------ Media Queries ------------------ */
@media (min-width: 1920px) {
  .not-implemented-page-container{
    min-height: calc(100vh - 525px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


