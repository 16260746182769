.about-website-text-box{
  background-color: #fff;
  margin: 2rem 0;
  padding: 3.2rem;
  width: 80%;
  /* max-width: 100%; */
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.about-website-text{
  font-size: 1.2rem;
  margin: 0.1rem 0;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
}



@media (max-width: 599px){
  .about-website-text-box{
  width: 100%;
  }
}

@media (max-width: 480px){
  .about-website-text-box{
  padding: 3.2rem 1rem;
  }
}