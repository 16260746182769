.consultation-tex-content {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000000;
}

.consultation-contact-box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
}

.consultation-contact-img {
  width: auto;
  height: 14rem;
  border-radius: 4.5px;
}

.consultation-contact-box-body {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  /* width: 100%; */
}

.consultation-contact-text-header {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6;
}

.consultation-contact-text-body {
  font-size: 1rem;
}

.consultation-contact-text-body a {
  text-decoration: none;
  color: inherit;
}

.consultation-contact-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.8rem;
}

.consultation-contact-btn {
  background-color: #3596f2;
  color: white;
  font-size: 0.8rem;
  width: 12rem;
}

.consultation-contact-btn:hover {
  background-color: #104d7f;
  color: white;
}

.consultation-contact-btn-icon {
  margin-right: 0.5rem;
}

/* ----------- QUERIES ----------- */

@media (min-width: 1920px) {
  .consultation-container{
    min-height: calc(100vh - 43vh);
  }
}

@media (max-width: 620px) {
  .consultation-container {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 499px) {
  .consultation-contact-box {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: relative;
    /* margin: 2rem auto; */
  }

  .consultation-contact-box-body::before,
  .consultation-contact-box-body::after {
    content: "";
    display: block;

    position: absolute;
    top: 30%;
    left: 0;
    width: 1px;
    height: 70%;
    background-color: #3596f2;
    z-index: -1;
    border-radius: 4.5px;
  }
  .consultation-contact-box-body::before {
    width: 100%;
    padding-bottom: 80%;
  }

  .consultation-contact-box-body::after {
    width: 100%;
    padding-bottom: 80%;
  }

  .consultation-contact-text-title,
  .consultation-contact-text-body {
    color: white;
  }

  .consultation-contact-btn {
    width: 100%;
    background-color: white;
    color: #3596f2;
  }

  .consultation-contact-btn:hover {
    width: 100%;
    background-color: #104d7f;
    color: white;
  }
}
