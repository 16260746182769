.examinations-container {
  margin-top: 10rem;
}

.examinations-secondary-heading {
  margin: 2rem 0;
}

.examination-search-container {
  margin: 0 auto;
  padding: 1rem 3rem;
}

.examinations-card-grid {
  margin: 2rem 0 5rem 0;
  gap: 3rem 0;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: stretch;
}

.col-sm-3 {
  width: 100%;
  justify-content: center;
}

.card {
  height: 100%;
  margin: 0 auto;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-footer {
  margin-top: auto;
}

.examination-card-title {
  text-align: center;
}

.examination-search-input {
  display: inline-block;
  margin: 0 2rem 2rem 0;
  width: 15rem;
  height: 2.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  border: 2px solid #3596f2;
  border-radius: 4.5px;
  padding: 0 0.5rem;
}

.examination-search-button {
  display: inline-block;
  margin: 0 auto;
  width: 8rem;
  height: 2.5rem;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.examination-search-button:hover {
  background-color: #104d7f;
  border-color: #104d7f;
  color: white;
}

.examinations-apply-button:link,
.examinations-apply-button:visited {
  display: block;
  margin: 1rem auto 0 auto;
  width: 15rem;
  height: 2.5rem;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.examinations-info-button:link,
.examinations-info-button:visited {
  display: block;
  margin: 1rem auto 0 auto;
  width: auto;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

/* -------------- LISTING ALPHABETICALLY -------------- */
/* --- LISTING ALPHABETICALLY NAV AND BUTTONS --- */

.exams-page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.pagination-prev-button,
.pagination-next-button {
  display: block;
  width: 8rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: white;
  border: 2px solid #3596f2;
  color: #3596f2;
}

.pagination-prev-button:hover,
.pagination-next-button:hover {
  background-color: #3596f2;
  border: 2px solid #3596f2;
  color: white;
}

/* --- LISTING ALPHABETICALLY CARDS --- */

.examination-table-title-container {
  padding: auto;
  margin: 0 2.5rem;
  background-color: #104d7f;
  color: white;
  border-radius: 9px 9px 0 0;
}

.examination-table-top {
  padding: 1rem !important;
}

.table-top-text {
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
}

.exam-card {
  width: 100%;
  margin: 0 auto;
}
.row .col-md-6:nth-child(even) {
  background-color: #d7eafc;
  border-radius: 4.5px;
}

.mb-3 {
  border: none;
  align-content: center;
  margin-bottom: 0;
  height: auto;
}

.col-md-6 {
  width: 100%;
  justify-content: center;
  align-content: center;
}

.card-body-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
}

.exam-card-title,
.exam-card-price-text,
.exam-card-issue-text {
  font-size: 1rem;
  font-weight: 500;
  width: 40rem;
  margin: auto 0;
}

.exam-card-price-text {
  width: 10rem;
  margin: auto 0;
  text-align: center;
}

.exam-card-issue-text {
  width: 10rem;
  margin: auto 0;
  text-align: right; /* Align text to the right */
}

.examinations-text {
  margin: 0 auto;
  width: 100%;
  line-height: 1.5;
}

/* ----------- QUERIES ----------- */

@media (max-width: 430px) {
  .exam-company-contact-item-text {
    width: 9rem;
    word-wrap: break-word;
  }
}
@media (max-width: 463px) {
  .examination-search-container {
    padding: 1rem 0;
  }
  .examination-search-input {
    width: 100%;
  }
  .examination-search-button {
    margin: 0 auto;
  }
}

@media (max-width: 714px) {
  .heading-secondary {
    text-align: center;
  }

  .examination-search-container {
    padding: 1rem 0;
  }
  .examination-search-input {
    width: 100%;
  }

  .examinations-card-grid {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .exams .container {
    padding: 0 0 3rem 0;
  }

  .card-body-row {
    flex-direction: column;
  }

  .exam-card-title,
  .exam-card-price-text,
  .exam-card-issue-text {
    font-size: 1rem;
    font-weight: 400;
    width: auto;
    margin: 0.2rem 0;
    text-align: start;
  }

  .examination-table-title-container {
    margin: 0;
  }
}

@media (min-width: 714px) and (max-width: 1030px) {
  .examinations-card-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .examination-table-title-container {
    margin: 0 1rem;
  }
}

@media (min-width: 1030px) and (max-width: 1350px) {
  .examinations-card-grid {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
}

@media (min-width: 1400px) {
  .examinations-card-grid {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    justify-content: center;
  }
}
