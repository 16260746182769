/* -------------  INTRODUCTION  ------------- */

.about-team-container {
  max-width: 120rem;
  padding: 0 3.2rem;
}

.about-team-heading {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 3.2rem;
  text-align: center;
}

.about-team-description {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 3.2rem;
  padding: 0 3.2rem;
  text-align: left;
  line-height: 1.5;
}

/* -------------  GRID  ------------- */

.about-team-members-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 3rem;
  justify-content: center;
  margin-top: 3.2rem;
}

/* -------------  CARD GRID  ------------- */

.about-team-management-card-members-inner-grid {
  display: grid;
  grid-template-rows: auto auto auto auto;
  transition: transform 0.5s ease;
}

/* -------------  CARD  ------------- */

.about-team-management-card {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

/* -------------  CARD IMAGE ------------- */

.about-team-management-img-container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-team-member-img {
  display: block;
  border-radius: 9px;
  margin-bottom: 2rem;
  width: 8rem;
  margin: 1rem auto;
}

/* -------------  CARD BODY  ------------- */

.about-team-member-name,
.about-team-member-position {
  text-align: center;
  margin-bottom: 1rem;
}

.about-team-member-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.about-team-member-position {
  font-size: 1rem;
  font-weight: 500;
  color:#666;
}

/* -------------  CONTACTS  ------------- */

.about-team-management-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-team-member-contact {
  color: #666;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-style: italic;
}

.about-team-member-contact-extra {
  margin-bottom: 0;
}

.about-team-join-text {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* -------------  JOIN BUTTON  ------------- */

.about-team-join-button:link,
.about-team-join-button:visited {
  display: block;
  margin: 2rem 0;
  width: 10rem;
  padding: 0.8rem;
  height: 3rem;
  align-items: center;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.about-team-join-button:hover {
  background-color: #104d7f;
  border-color: #104d7f;
  color: white;
}

/* ----------- QUERIES ----------- */

/* @media (min-width: 581px) and (max-width: 925px) {
  .about-team-members-grid {
    grid-template-columns: repeat(2, auto);
  }
  .medical-about-team-members-card-inner-grid:nth-child(3) {
    grid-column: 1 / span 2;
    text-align: center;
  }

  .medical-about-team-members-card-inner-grid:nth-child(3) .about-team-member-img {
    width: calc(60% / 2);
  }
} */

@media (max-width: 580px) {
  .about-team-description {
    margin: 3rem 0;
    padding: 0 2rem;
  }

  .about-team-members-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about-team-grid-container {
    margin-bottom: 3rem;
  }
}
