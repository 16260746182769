.empty-list-message {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

/*  ---------- CARD ----------  */
.about-career-position-card-grid {
  margin: 2rem 0 5rem 0;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: stretch;
}

.col-sm-3 {
  width: 100%;
  justify-content: center;
}

.card {
  height: 80%;
  margin: 0 auto;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chem-career-card-title{
  text-align: center;
}

.card-title {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
}

.card-text {
  text-align: center;
}

.card-footer {
  margin-top: auto;
  background-color: white;
  border-top: none;
  padding: 0;
  width: auto;
}

/*  ---------- BUTTONS ----------  */
.about-career-apply-button:link,
.about-career-apply-button:visited {
  display: block;
  margin: 1rem auto 0 auto;
  width: auto;
  height: 3rem;
  color: white;
  background-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.about-career-apply-button:hover {
  background-color: white;
  border-color: #104d7f;
  color: #3596f2;
}

.about-career-info-button:link,
.about-career-info-button:visited {
  display: block;
  margin: 1rem auto 0 auto;
  width: auto;
  height: 3rem;
  color: #3596f2;
  background-color: white;
  border-color: #3596f2;
  font-size: 1rem;
  font-weight: 600;
}

.about-career-info-button:hover {
  background-color: #104d7f;
  border-color: #104d7f;
  color: white;
}

/* ----------- QUERIES ----------- */

@media (max-width: 714px) {
  .about-career-position-card-grid {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .card {
    width: 75%;
  }
}

@media (min-width: 714px) and (max-width: 1030px) {
  .about-career-position-card-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .card {
    width: 75%;
  }
}

@media (min-width: 1030px) and (max-width: 1350px) {
  .about-career-position-card-grid {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }

  .card {
    width: 80%;
  }
}

@media (min-width: 1350px) {
  .about-career-position-card-grid {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
  }

  .card {
    width: 80%;
  }
}
