/* -------------- PACKAGES - LEFT -------------- */

.chemical-exam-package-box-left {
  width: 100%;
  height: 100%;
  padding: 2rem 5.2rem;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
}

.chemical-exam-package-box-body {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto;
  row-gap: 0 !important;
}

.chemical-exam-package-box-title-left {
  height: 3rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
}

.chemical-exam-package-box-text-left {
  width: 75%;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8;
  text-align: justify;
  margin: 1rem 0 3rem 0;
}

/* -------------- PACKAGES - LEFT-BLUE -------------- */

.chemical-exam-package-box-left-blue {
  width: 100%;
  height: 100%;
  padding: 2rem 5.2rem;
  margin: 2rem 0;
  background-color: #104f7d;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
}

.chemical-exam-package-box-title-left-blue {
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  text-align: left;
  margin-bottom: 1rem;
}

.chemical-exam-package-box-text-left-blue {
  width: 75%;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8;
  text-align: justify;
  margin: 1rem 0 3rem 0;
}

/* -------------- BUTTONS -------------- */

.chemical-exam-package-box-button-left-blue:hover {
  background-color: white !important;
  color: #104f7d !important;
}

/* -------------- MEDIA QUERIES -------------- */
@media (max-width: 850px) {
  .chemical-exam-package-box-text-left {
    width: 100%;
    padding: 2rem 0rem;
  }

  .chemical-exam-package-box-text-left-blue {
    width: 100%;
    padding: 2rem 0rem;
  }
}

@media (max-width: 650px) {
  .chemical-exam-package-box-left {
    padding: 2rem 3rem;
  }

  .chemical-exam-package-box-left-blue {
    padding: 2rem 3rem;
  }
}

@media (max-width: 499px) {
  .chemical-exam-container {
    padding: 0rem !important;
  }

  .chemical-exam-package-box-left,
  .chemical-exam-package-box-left-blue {
    padding: 2rem 2rem;
  }
  .chemical-exam-package-box-text-left,
  .chemical-exam-package-box-text-left-blue {
    text-align: left;
  }

  .chemical-exam-package-box-button-left {
    background-color: #3596f2 !important;
    color: white !important;
  }
}
