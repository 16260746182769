.about-career {
  margin-top: 7.2rem;
}

.about-career-description,
.about-carrer-text {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 3.2rem;
}

/* ------------------ Media Queries ------------------ */
@media (min-width: 1920px) {
  .about-career {
    min-height: calc(100vh - 450px);
  }
}
