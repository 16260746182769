.navbar-container{
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: white;
  background-color: #3596F2;
  padding: 1.1rem 1rem;
}

.navbar-brand{
  margin: auto 0;
  position: relative;
}
.navbar-logo-text{
  position: absolute;
  top: 1.2rem;
  left: 4rem;
  padding: auto 0;
}

.navbar-logo-text{
  text-decoration: none;
  color: white;
}
.navbar-logo{
  margin-right: 1rem;
}

.medical-nav-first{
  width: 9rem;
}

.nav-link{
  color: white;
}

.nav-link:last-child(){
  margin-right: 3.2rem;
}

.dropdown-menu .show{
  width: auto;
  background-color: #104d7f;
}

.dropdown-menu{
  background-color: transparent;
}

.nav-link .dropdown-toggle .dropdown-menu .show{
  background-color: #104d7f;
  min-width: min-content;
  color: white;
}

.dropdown-item{
  background-color: inherit;
  width: auto;
}

.dropdown-menu {
  top: 100%; /* Position the menu below the navbar */
  left: 0; /* Adjust this value as needed */
  /* You can also use 'right' property to control the positioning from the right edge */
  /* Other styles remain the same */
  background-color: white;
  width: auto;
  color: #104d7f;
  border-radius: 4px;
}



.dropdown-item:hover{
  font-weight: 600;
  color: #3596F2;
}

/*  ----------- QUERIES --------- */

@media (max-width: 991px) {
  .dropdown-item:focus {
    outline: none; 
  } 

  .dropdown-menu{
    background-color: inherit;
    text-align: left;
    text-decoration: white;
    border: 0;
  }

  .dropdown-item{
    color: white;
    font-weight: 500;
  }

  .dropdown-item:hover{
    background-color: inherit;
    color: white;
    font-weight: 600;
  }

}