.consultation-contact-box-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chemical-consultation-contact-box{
    display: flex;
    flex-direction: row;
    justify-content:left;
    align-items: center;
    width: 100%;
}

.chemical-consultation-contact-box-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    padding: 2rem 0;
}

/* ----------- QUERIES ----------- */

@media (max-width: 620px) {
  .consultation-container {
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 499px) {
  .chemical-consultation-contact-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 2rem auto;
  }

  .chemical-consultation-contact-box-body::before,
  .chemical-consultation-contact-box-body::after {
    content: "";
    display: block;

    position: absolute;
    top: 8%;
    left: 0;
    width: 100%;
    height: 70%;
    z-index: -1;
    border-radius: 4.5px;
    text-decoration: #333;
  }

  .consultation-contact-text-title,
  .consultation-contact-text-body {
    color: white;
  }

  .consultation-contact-btn {
    width: 100%;
    background-color: white;
    color: #3596f2;
  }

  .consultation-contact-btn:hover {
    width: 100%;
    background-color: #104d7f;
    color: white;
  }

  .chemical-consultation-btn{
    width: 80%;
    color: white !important;
    background-color: #3596f2 !important;
  }
}
