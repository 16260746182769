.chemical-about-team-members-grid {
  display: grid;
  grid-template-columns: repeat(2, auto) !important;
  gap: 2rem;
  justify-content: left !important;
  width: 100%;
}

.chemical-about-team-members-leaders-grid {
  display: grid;
  grid-template-columns: repeat(3, auto) !important;
  gap: 2rem;
  justify-content: left !important;
  width: 100%;
}

.chem-about-team-members-card-inner-grid:nth-child(3) {
  grid-column: auto;
}

.aligned-left {
  justify-content: left !important;
  text-align: left !important;
}

.chemical-about-team-member-img {
  margin: 1rem 0 !important;
  width: 8rem !important;
}

.about-heading-secondary {
  text-align: left !important;
}

/* ----------------- QUERIES ----------------- */

@media (max-width: 925px) {
  .about-heading-secondary {
    text-align: left;
  }
}

@media (min-width: 581px) and (max-width: 898px) {
  .chemical-about-team-members-leaders-grid {
    grid-template-columns: repeat(2, auto) !important;
  }
}
@media (max-width: 580px) {
  .about-team-grid-container {
    margin-bottom: 3rem;
  }

  .chemical-about-team-description {
    margin: 3rem 0;
    padding: 0 2rem !important;
  }

  .chemical-about-team-members-grid {
    grid-template-columns: 1fr !important;
    gap: 2rem;
  }

  .chemical-about-team-grid-container {
    margin-bottom: 3rem;
  }

  .placeholder-image {
    display: none;
  }
}
