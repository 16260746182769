.exam-company-contact-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0 5rem 0;
}

.exam-company-contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.exam-company-contact-item-icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3596f2;
  border: 4px solid #3596f2;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #fff;
  transition: background 0.3s, color 0.3s, box-shadow 0.3s, border 0.3s; /* Added border transition */
  font-size: 2rem;
}

.exam-company-contact-item:hover .exam-company-contact-item-icon {
  color: #fff; /* Icon color on hover */
  background: #104f7d;
  border-color: #104f7d;
  box-shadow: none;
  border: 4px solid #104f7d;
}

.exam-company-contact-item-icon-inner:hover {
  color: #fff;
}



.exam-company-contact-item-text {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6;
  color: #333;
  margin-left: 1rem;
  text-decoration: none;
  cursor: pointer;
}

/* ---------- QUERIES ---------- */

@media (max-width: 499px) {
  .exam-company-contact-item-text {
    width: 9.5rem;
  }
}